<template>
  <div>
    <b-row class="px-5 py-3"><b-table responsive striped :items="miembros" :fields="fields" table-variant="warning"></b-table></b-row>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
export default {
  name: "MembersList",
  data() {
    return {
      fields: [
        {
          key: "clanRank",
          label: "Rango",
          formatter: value =>{
            return "#"+value
          }
        },
        {
          key: "name",
          label: "Nombre",
        },
        {
          key: "role",
          label: "Rol",
          formatter: (value) => {
            if (value == "leader") return "Líder";
            if (value == "coLeader") return "Colíder";
            if (value == "admin") return "Veterano";
            if (value == "member") return "Miembro";
            return value;
          },
        },
        {
          key: "expLevel",
          label: "Nivel",
          class: "text-center"
        },
        {
          key: "trophies",
          label: "Trofeos",
          class: "text-center"
        },
        {
          key: "donations",
          label: "Donaciones hechas",
          class: "text-center"
        },
        {
          key: "donationsReceived",
          label: "Donaciones recibidas",
          class: "text-center"
        },
      ],
    };
  },
  computed:{
    miembros: get('clandata@memberList'),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.b-table {
  width: 100%;
}
</style>
