<template>
  <div >
    <HeaderComponent />
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col sm="12" md="9" lg="9" xl="9">
          <router-view />
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

const axios = require("axios");

export default {
  name: "app",
  components: {
    HeaderComponent,
  },
  created(){
    this.retrieveClanData()
  },
  methods:{
    retrieveClanData() {
      var config = {
        //url: "http://127.0.0.1:3000/retrieveClanData",
        url: "https://secret-oasis-22291.herokuapp.com/retrieveClanData",
      };

      axios(config)
        .then((response) => {
          this.$store.set("clandata",response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
