<template>
  <b-navbar :sticky="true" type="light" variant="themed-2" class="shadow">
    <b-navbar-brand href="#">
      <img
        src="https://api-assets.clashofclans.com/badges/70/j6ZNFVsqOKdIRKWfWhh6Pv36oiCsTK3JcuQHtiMzQ68.png"
        alt="Escudo"
        width="50px"
      />
      <span class="text-themed-3 text-capitalize fs-3">{{ nombreClan }}</span>
    </b-navbar-brand>
    <b-navbar-nav>
      <b-nav-item href="#">Home</b-nav-item>
      <b-nav-item href="#" disabled>FAQ's</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { get } from 'vuex-pathify';
export default {
  name: "HeaderComponent",
  data() {
    return {
    };
  },
  computed:{
    nombreClan: get('clandata@name'),
  },
};
</script>

<style scoped>
</style>