import Vue from 'vue'
import Vuex from 'vuex'
import pathify, { make } from 'vuex-pathify'

const state = {
  loading: false,
  clandata: {}
};

const mutations = {
  ...make.mutations(state),
};
const actions = make.actions(state)

Vue.use(Vuex)
export default new Vuex.Store({
  plugins: [
    pathify.plugin
  ],

  state,
  getters: {
  },
  mutations,
  actions,
  modules: {
  }
})
